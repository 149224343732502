<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-form
      ref="teamInvitePhoneRole"
      v-model="formValidated"
    >
      <v-row
        dense
        class="pa-4"
      >
        <v-col cols="12">
          <v-select
            :disabled="processing"
            :label="$t('role')"
            v-model="role"
            :items="roles"
            item-text="name"
            item-value="role_id"
            filled
            dense
            required
          />
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col
          cols="12"
          class="px-0 pt-0"
        >
          <v-divider />
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
        dense
        align="center"
      >
        <v-col
          cols="12"
          md="auto"
        >
          <v-row
            class="pa-0"
            dense
          >
            <v-col
              cols="12"
              md="auto"
              class="py-0"
            >
              <v-btn
                block
                large
                color="error"
                text
                :disabled="processing"
                @click="abort"
              >
                {{ $t('abort') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="auto"
              class="py-0"
            >
              <v-btn
                block
                large
                color="error"
                :disabled="processing"
                @click="back"
              >
                {{ $t('previous') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="auto"
        >
          <v-btn
            block
            large
            color="primary"
            :loading="processing"
            :disabled="processing || !formValidated"
            @click="nextStep"
          >
            {{ $t('next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
	name: "TeamInviteRoleSelection",
	data() {
		return {
			role: 3,
			formValidated: false
		}
	},
	computed: {
		processing() {
			return this.$store.state.isProcessingTeamInvite
		},
		rules() {
			return this.$store.getters.rules
		},
		roles() {
			return this.$store.state.roles
		},
	},
	methods: {
		abort() {
			this.$emit('close')
		},
		back() {
			this.$emit('back')
		},
		nextStep() {
			this.$emit('next', { role: this.role })
		}
	}
}
</script>

<style scoped>

</style>