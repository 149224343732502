<template>
  <v-dialog
    :persistent="processing"
    v-if="value"
    max-width="800"
    ref="dialog"
    :value="value"
    @input="updateValue"
    @click:outside="abort"
  >
    <v-card>
      <v-card-title>{{ $t('addToTeam') }}</v-card-title>
      <v-card-subtitle>{{ $t('inviteANewMemberToTheTeam') }}</v-card-subtitle>
      <v-divider />
      <v-card-text class="pa-0">
        <v-stepper
          v-model="step"
          class="elevation-0"
        >
          <v-stepper-header class="elevation-0">
            <v-stepper-step step="1">
              {{ $t('nameAndEmail') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step	step="2">
              {{ $t('additionalInfo') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step step="3">
              {{ $t('password') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step step="4">
              {{ $t('role') }}
            </v-stepper-step>
            <v-divider />
            <v-stepper-step step="5">
              {{ $t('sendInvite') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-divider />
          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="px-0 pb-3 pt-3"
            >
              <TeamInviteNameEmail
                @close="abort"
                @next="nameAndEmailStep"
              />
            </v-stepper-content>
            <v-stepper-content
              step="2"
              class="px-0 pb-3 pt-3"
            >
              <TeamInviteAdditionalInfo
                @close="abort"
                @back="back"
                @next="additionalInfoStep"
              />
            </v-stepper-content>
            <v-stepper-content
              step="3"
              class="px-0 pb-3 pt-3"
            >
              <TeamInvitePassword
                @close="abort"
                @back="back"
                @next="passwordStep"
              />
            </v-stepper-content>
            <v-stepper-content
              step="4"
              class="px-0 pb-3 pt-3"
            >
              <TeamInviteRoleSelection
                @close="abort"
                @back="back"
                @next="roleStep"
              />
            </v-stepper-content>
            <v-stepper-content
              step="5"
              class="px-0 pb-3 pt-3"
            >
              <TeamInviteFinal
                @close="abort"
                @back="back"
                @next="invite"
                :user-name-exists="userNameExists"
                :user-email-exists="userEmailExists"
                :user-name="displayName"
                :email="email"
                :user-already-in-team="userAlreadyInTeam"
				:email-or-username-exists="emailOrUsernameExists"
                :role="role"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-snackbar
        v-model="showResult"
        absolute
        top
        :color="snackColor"
        :timeout="3000"
      >
        {{ snackText }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import TeamInviteNameEmail from "@/components/team/TeamInviteNameEmail"
import TeamInvitePassword from "@/components/team/TeamInvitePassword"
import TeamInviteFinal from "@/components/team/TeamInviteFinal"
import TeamInviteAdditionalInfo from "@/components/team/TeamInviteAdditionalInfo"
import TeamInviteRoleSelection from "@/components/team/TeamInviteRoleSelection"

export default {
	name: 'TeamInviteDialog',
	components: {
		TeamInviteRoleSelection,
		TeamInviteAdditionalInfo,
		TeamInviteFinal,
		TeamInvitePassword,
		TeamInviteNameEmail
	},
	props: {
		value:{
			type: Boolean
		}
	},
	data()  {
		return {
			//processing: false,
			email: null,
			phone: null,
			autoPassword: true,
			password: '',
			tempPassword: '',
			displayName: null,
			role: 3,
			formValidated: false,
			showResult: false,
			resultSuccess: null,
			passwordStrength: 0,
			userNameExists: false,
			userEmailExists: false,
			userAlreadyInTeam: false,
			emailOrUsernameExists: false,
			step: 1
		}
	},
	mounted() {
		//
	},
	beforeDestroy() {
		this.resetValues()
	},
	computed: {
		processing() {
			return this.$store.state.isProcessingTeamInvite
		},
		roles() {
			return this.$store.state.roles
		},
		rules() {
			return this.$store.getters.rules
		},
		snackColor() {
			if(this.resultSuccess) {
				return 'success'
			} else {
				return 'error'
			}
		},
		snackText() {
			if(this.resultSuccess) {
				return this.$t('inviteSuccessfullySent')
			} else {
				return this.$t('unableToSendInvite')
			}
		}
	},
	methods: {
		back() {
			if(this.userAlreadyInTeam && this.step === 5) {
				this.step = 1
				this.userAlreadyInTeam = false
			} else if(this.emailOrUsernameExists && this.step === 5) {
				this.step = 1
				this.emailOrUsernameExists = false
			} else if((this.userNameExists || this.userEmailExists) && this.step === 4) {
				this.step = 1
				this.userNameExists = false
				this.userEmailExists = false
			} else {
				this.step--
			}
		},
		nameAndEmailStep(value) {
			this.displayName = value.displayName
			this.email = value.email

			if(value.userAlreadyInTeam) {
				this.userAlreadyInTeam = value.userAlreadyInTeam
				this.step = 5
			} else if(value.emailOrUsernameExists) {
				this.emailOrUsernameExists = value.emailOrUsernameExists
				this.userEmailExists = value.userEmailExists
				this.userNameExists = value.userNameExists
				this.step = 5				
			} else if(value.userNameExists || value.userEmailExists) {
				this.userNameExists = value.userNameExists
				this.userEmailExists = value.userEmailExists
				this.step = 4
			} else {
				this.step++
			}
		},
		additionalInfoStep(value) {
			this.phone = value.phone
			this.step++
		},
		passwordStep(value) {
			this.autoPassword = value.autoPassword
			this.password = value.password
			this.step++
		},
		roleStep(value) {
			this.role = value.role
			this.step++
		},
		resetValues() {
			this.displayName = null
			this.email = null
			this.phone = null
			this.password = ''
			this.tempPassword = ''
			this.autoPassword = true
			this.role = 3
			this.step = 1
			this.userEmailExists = false
			this.userAlreadyInTeam = false
			this.emailOrUsernameAlreadyUsed = false
			this.userNameExists = false
		},
		updateValue() {
			this.$emit('input', this.$refs.dialog.value)
		},
		abort() {
			this.resetValues()
			this.$emit('input', false)
		},
		invite() {
			let payload = {
				email: this.email,
				displayName: this.displayName,
				role_id: this.role,
				phone: this.phone
			}

			if(!this.phone) {
				payload.phone = ''
			}

			if(!this.userNameExists && !this.userEmailExists) {
				if(!this.autoPassword) {
					payload.password = this.password
				}
			}

			this.$store.dispatch('inviteToTeam', payload).then(result => {
				if(result) {
					const message = this.$t('youHaveSuccessfullyAddedStringToYourTeam', {string: this.displayName})
					const subMessage = result.text ?
							(this.password ? this.$t('thePasswordForThisUserIs') : this.$t('userDidNotExistInOurSystemsAndCreatedTheirAutoPasswordIs') + '\n') +
							result.text + '\n' +
							this.$t('noteThatWeDoNotSendPasswordsThroughEmailYouWillHaveToSend') +
							this.$t('pleaseRemindUserToChangePasswordWhenTheyLogIn') : null
          this.$store.commit('updateActionSuccess', {
            message: message,
            subMessage: subMessage
          })

					this.abort()
					this.$store.dispatch('getTeam')
				} else {
					this.showResult = true
					this.resultSuccess = result
				}
			})
		}
	}
}
</script>

<style scoped>

</style>